import { type HTMLAttributes, memo, type ReactElement, useCallback, useMemo, useState } from 'react';
import { Autocomplete, Avatar, Box, FormControl, LinearProgress, Stack, TextField } from '@mui/material';
import { keyBy } from 'lodash';

import { useFilter, useGetOptionLabel, useHandleSelectionChange } from './hooks';
import type { MerchantSelectorProps } from './types';
import { useQuery } from 'react-query';
import { gqlClient, isCompanyAffiliate, isVendor, Merchant } from '../../../services/FinaloopGql';

async function searchMerchants({ queryKey: [_, companyId, term] }: any) {
  return await gqlClient.query({
    __name: 'searchMerchants',
    searchMerchants: [
      { input: { companyId, searchTerm: term } },
      {
        on_CompanyAffiliate: {
          id: true,
          name: true,
          type: true,
          __typename: true,
        },
        on_Customer: {
          id: true,
          name: true,
          __typename: true,
        },
        on_Vendor: {
          id: true,
          name: true,
          logoUrl: true,
          source: true,
          __typename: true,
        },
        on_Institution: {
          id: true,
          name: true,
          logoUrl: true,
          __typename: true,
        }
      },
    ],
  });
}

export const MerchantSelector = memo((props: MerchantSelectorProps): ReactElement => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isAdding, setIsAdding] = useState(false);

  const { data: merchants = [] } = useQuery(['merchants', props.companyId, searchTerm], searchMerchants, {
    // only fetch search terms longer than 2 characters
    enabled: searchTerm.length > 1,
    refetchOnWindowFocus: false,
    // refresh cache after 10 seconds (watch the network tab!)
    staleTime: 10 * 1000,
    select: ({ searchMerchants }) => searchMerchants,
  });

  const merchantsDictionary = useMemo(
    () => keyBy(merchants, ({ id, __typename }) => `${__typename}-${id}`),
    [merchants],
  );

  const handleSelectionChange = useHandleSelectionChange({ ...props, setIsAdding });
  const filterOptions = useFilter(false);
  const getOptionLabel = useGetOptionLabel();

  const renderInput = useCallback((params: any) => <TextField {...params} label="Merchant" variant="filled" />, []);
  const updateSearchTerm = useCallback((_event: any, searchTerm: string) => setSearchTerm(searchTerm), []);

  const selectedMerchant = merchantsDictionary[props.value?.id || ''];

  const renderOption = useCallback(
    (props: HTMLAttributes<HTMLLIElement>, option: Merchant) => (
      <li {...props} key={option.id} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', gap: 2 }}>
          <span>{option ? optionAvatar(option) : undefined}</span>
          <span>{option.name.trim()}</span>
        </div>

        <span style={{ flexShrink: 0, marginLeft: '8px', fontStyle: 'italic', fontSize: '70%' }}>
          {option.__typename}
          {isVendor(option) ? ` (${option.source})` : null}
          {isCompanyAffiliate(option) ? ` (${option.type})` : null}
        </span>
      </li>
    ),
    [],
  );

  return (
    <Stack sx={{ width: '100%' }}>
      <FormControl fullWidth>
        <Autocomplete
          clearOnBlur
          disabled={!!props.isDisabled || !!props.isLoading || isAdding}
          filterOptions={filterOptions}
          freeSolo={props.canAdd}
          getOptionLabel={getOptionLabel}
          handleHomeEndKeys
          id="merchant-selector--autocomplete"
          onInputChange={updateSearchTerm}
          isOptionEqualToValue={(option: unknown, value: unknown) =>
            (option as { id: string }).id === (value as { id: string })?.id &&
            (option as { __typename: string }).__typename === (value as { __typename: string })?.__typename
          }
          onChange={handleSelectionChange}
          options={merchants}
          renderInput={renderInput}
          renderOption={renderOption as any}
          selectOnFocus
          defaultValue={props.value}
          value={selectedMerchant}
        />
      </FormControl>
      <Box sx={{ height: '5px' }}>{props.isLoading || isAdding ? <LinearProgress variant="query" /> : undefined}</Box>
    </Stack>
  );
});

const optionAvatar = (option: Merchant | { label: string }) => {
  const label = (option as any).name || (option as any).label;
  let letter = label.trim().charAt(0).toUpperCase();
  letter = letter === '[' ? '?' : letter;

  return (
    <Avatar sx={{ width: 24, height: 24, marginRight: '10px' }} src={(option as any).logoUrl}>
      {letter}
    </Avatar>
  );
};
